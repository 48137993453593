//mobile Converter 
/*function mobile_convert(selector,games,game) {
	if($('#mobile_type').attr('value') == 'mob') {
	
		if ($(games).length > 0 || $(game).length > 0) {
			subselector=',#content_container>:not('+games+','+game+',h1,div:has('+games+'),p:has(img))';
		}else subselector='';
		
		$('#content_container img').removeAttr('width').removeAttr('height');
		if(!$('#content_container img').parent('a')){
			$('#content_container img').wrap('<a href="/go/money" rel="nofollow"></a>');	
		}
		
		$(selector+subselector).css('display','none');
	}
}*/

//меню мобильной версии
function menu_mobversion() {
	if ($('#mobile_header').length > 0) {
		var mw = $('#content_container').width();
		$(window).resize(function(){
			mw = $('#content_container').width();
		});
		
		$('#mobile_menu_btn').click(function(){
			$(this).toggleClass('menu_clicked');
			$('#mobile_sitebar').toggleClass('menu_show');
			if (!$(this).hasClass('menu_clicked')) {
				$('#mobile_sitebar').animate({'max-width':0},500,function(){
					$('#content_container').css('width','auto');
				});
			}else {
				$('#content_container').css('width',mw+'px');
				$('#mobile_sitebar').animate({'max-width':265},500);
			}
		});
		$('#games_platforms').click(function(){
			$(this).toggleClass('makers_show');
			if ($(this).hasClass('makers_show')) {
				$('#sidebar_makers').slideDown('fast');
			}else
				$('#sidebar_makers').slideUp('fast');
		});
	}
}

//кнопка больше автоматов
function more_mobiles() {
	
	if($('#sfera_mobile').next('.go').length > 0) {
		var total_height=Math.ceil(($('#sfera_mobile a').length) / 3)*154;

		$('#sfera_mobile').next('.go').click(function(){
			var new_height = parseInt($('#sfera_mobile').height())+308;
			
			if (new_height >= total_height) {
				$(this).slideUp('fast');
				$('#sfera_mobile').height(total_height);
			}
			if (new_height < total_height){ 
				$('#sfera_mobile').height(new_height);
			}
			return false;
		});
	} 
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}
// Переключатель версий сайтов с "мобильной" на "полную"
function site_versions() {
	if ($('#site_version').length > 0) {
		$('#site_version').click(function(){
			var check_cookie = getCookie("site_version");
			if (check_cookie == 'mobile') {
				document.cookie="site_version=web";
				document.location.href=location.href;
			}else {
				document.cookie="site_version=mobile";
				document.location.href=location.href;
			}
			return false;	
		});
	}
}

function menu_light() {
	if ($('#mobile_holder').length>0) {
		$('#mobile_holder').find('a[href="'+document.location.pathname+'"]').addClass('current');
	}
}
// Переключатель слайдов
function sitebar_slider (sitebar_slide)
		{
			var slides = $('#sitebar_slider_circles div').length;
			
			$('#sitebar_banner_next').click(function()
			{
				$('#sitebar_banners a.show').addClass("hided").removeClass("show")
				sitebar_slide++;if (sitebar_slide > slides){sitebar_slide=1;}
				$('#sitebar_banners a:nth-child('+sitebar_slide+')').addClass("show").removeClass("hided");
				$('#sitebar_slider_circles div.current').removeClass("current");
				$('#sitebar_slider_circles div:nth-child('+sitebar_slide+')').addClass("current");
				return false; 
			});
			$('#sitebar_banner_previous').click(function()
			{
				$('#sitebar_banners a.show').addClass("hided").removeClass("show")
				sitebar_slide--;if (sitebar_slide == 0){sitebar_slide=slides;}
				$('#sitebar_banners a:nth-child('+sitebar_slide+')').addClass("show").removeClass("hided");
				$('#sitebar_slider_circles div.current').removeClass("current");
				$('#sitebar_slider_circles div:nth-child('+sitebar_slide+')').addClass("current");	
				return false;
			});
			$('#sitebar_slider_circles div').click(function()
			{
				$('#sitebar_slider_circles div.current').removeClass("current");
				$(this).addClass("current");
				$('#sitebar_banners a:nth-child('+sitebar_slide+')').removeClass("show").addClass("hided");	
				sitebar_slide=$(this).html();
				$('#sitebar_banners a:nth-child('+sitebar_slide+')').removeClass("hided").addClass("show");
			});
			$('#sitebar_slider_circles div.current').removeClass("current");
			$('#sitebar_slider_circles div:nth-child('+sitebar_slide+')').addClass("current");
			sitebar_slider_main_interval = setInterval (function(){
			var sitebar_slide_last = sitebar_slide - 1;
			sitebar_slide++;
			sitebar_slide_last = sitebar_slide - 1;
			if (sitebar_slide > slides)
				{sitebar_slide=1;}
			$('#sitebar_banners a:nth-child('+sitebar_slide+')').removeClass("hided").addClass("show");
			$('#sitebar_banners a:nth-child('+sitebar_slide_last+')').removeClass("show").addClass("hided");
			$('#sitebar_slider_circles div.current').removeClass("current");
			$('#sitebar_slider_circles div:nth-child('+sitebar_slide+')').addClass("current");	
		},4000);}	
		
// Наведение на игру 
function initialize_interval(){
	if($('div.all_slots_container .lighted').hasClass('animation')) {
		all_slots_remove = 1;
		var steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		var i = 0;
		var j = 0;
		all_slots = setInterval(function(){
			if(i == 0)	{
				j = 12;
			}
			if(i == 1)	{
				j = 1;
			}
			if(i == 11)	{
				i = 0;
			}
			all_slots_remove = steps[i];
			$('div.all_slots_container .lighted.animation').removeClass('light_'+steps[j-1]);
			$('div.all_slots_container .lighted.animation').addClass('light_'+steps[i]);
			j++;
			i++;
		}, 150);
	}else {
		$('div.all_slots_container .lighted').removeClass('light_'+all_slots_remove);
		$('div.all_slots_container .lighted').addClass('light_1');
		clearInterval(all_slots);
	}
}

function slide_sitebar_banners(target_offset) {
	var window_scroll = $(window).scrollTop()+15;
	if (window_scroll > target_offset) {
		$('#banners_cover').css('position','fixed').css('top','15px');
	}
	else {
		$('#banners_cover').css('position','relative').css('top',0);
	} 
}
function flash(){
if($('#on_money').length > 0){
	function miganie(dontmove){
			if($('#on_money').hasClass("hover")){
				if(dontmove != 1){
				$('#on_money').removeClass("hover");
				}
			}	else	{
				$('#on_money').addClass("hover");
			}
		}
		var intervalMig = setInterval(miganie,1000);
	$('#on_money').hover(
						  function () {
						  	clearInterval(intervalMig);
						    miganie(1);
						  },
						  function () {
							intervalMig = setInterval(miganie,1000);
						  }
						);
}
if($('.money_button').length > 0){
	function miganie(dontmove){
			if($('.money_button').hasClass("hover")){
				if(dontmove != 1){
				$('.money_button').removeClass("hover");
				}
			}	else	{
				$('.money_button').addClass("hover");
			}
		}
		var intervalMig = setInterval(miganie,1000);
	$('.money_button').hover(
						  function () {
						  	clearInterval(intervalMig);
						    miganie(1);
						  },
						  function () {
							intervalMig = setInterval(miganie,1000);
						  }
						);
}		
}

function gamego() {
	if ($('#on_money').length > 0) {
		$('#content img').css('cursor','pointer');
		$('#content img').click(function(){
			$('.btn-startgame').click();
		});
	}
}

$(document).ready(function(){
	gamego();
	menu_light();
	menu_mobversion();
	site_versions();
/*
	getSizeGameBox();
	$(window).resize(function(){
		getSizeGameBox();
	});
*/
	more_mobiles();
	flash();
	
/* ширина контента */
if ($('#mobile_holder').length > 0) {
	if ($(window).width()-22 > 1100) {
		$('#content_container').css('max-width',1100+'px');
	}else $('#content_container').css('max-width',$(window).width()-22+'px');
	
	$(window).resize(function(){
		if ($(window).width()-22 > 1100) {
			$('#content_container').css('max-width',1100+'px');
		}else $('#content_container').css('max-width',$(window).width()-22+'px');
	});
}
/* sitebar banner slider */
	if($("#on_money > a").length > 0)	{
		$(window).scrollTop($("#breadCrumbs").offset().top-10);
	}
	if ($('#sitebar_banners').length>0)
	{
		
		$('#sitebar_banners').hover(function(){clearInterval(sitebar_slider_main_interval);},
									function(){var starter=$('#sitebar_slider_circles div.current').html();sitebar_slider (starter);});
		$('#sitebar_banners a:nth-child(1)').removeClass("hided").addClass("show");
		sitebar_slider (1);
		
		var sitebar_banners_offset = $('#sitebar_banners').offset().top;
		slide_sitebar_banners(sitebar_banners_offset);
		$(window).scroll(function(){
			slide_sitebar_banners(sitebar_banners_offset);	
		});
	}
/* Like dislike */
	if ($('#rating').length>0)
	{
		$('#like_dislike_form div').click(function()
		{
			if (!!$(this).children('a').hasClass("like") || !!$(this).children('a').hasClass("dislike"))
				{
					var id = $('#docid').val();
					if ($(this).children('a').hasClass("like"))
					{
						$('a.like').addClass("liked").removeClass("like");
						$('a.disliked').addClass("dislike").removeClass("disliked");
						$('a.liked').parent('div').addClass("cursor_default").removeClass("cursor_pointer");
						$('a.dislike').parent('div').addClass("cursor_pointer").removeClass("cursor_default");	
						var like_dislike = 1;						
					}else if ($(this).children('a').hasClass("dislike"))
					{
						$('a.dislike').addClass("disliked").removeClass("dislike");
						$('a.liked').addClass("like").removeClass("liked");
						$('a.disliked').parent('div').addClass("cursor_default").removeClass("cursor_pointer");
						$('a.like').parent('div').addClass("cursor_pointer").removeClass("cursor_default");
						var like_dislike = 0;				
					}
					$.post("/actions/like_dislike.php",{like_dislike:like_dislike,docid:id},function(data)
					{
					var id = $('#docid').val();	
					if (data != 'ok'){}else
					{
						$.getJSON('/actions/like_dislike_info.php?docid=' + id,function(data){
							$('a.like').html(data.likes);
							$('a.dislike').html(data.dislikes);
							$('a.liked').html(data.likes);
							$('a.disliked').html(data.dislikes);
							})
					}
					});
				}
			return false;
		});
	}	
	
	/* Анимация света */
	if($('.all_slots_container .lighted').length > 0) {
		$('.slot_container').hover(function()
		{
			$(this).find('.lighted').addClass('animation'); initialize_interval();
		}, function(){$('.slot_container .lighted').removeClass('animation'); initialize_interval();});		
	}
	
	/* Социалки */
	if ($('#share42').length > 0)
		{
			var base_position=$('#share42').offset().top;
			$(window).scroll(function()
			{
				var current_position=$(window).scrollTop();
				if (base_position<=current_position)
				{
					$('#share42').css('position','fixed');	
				}else {$('#share42').css('position','relative');}
			});
		}
		
	//mobile_convert('#mobile_menu_btn,#mobile_sitebar,#mobile_fixed_btns,.B_crumbBox,#mobile_footer,#month_box','.all_slots_container','#frame_back');
	});

	function getSizeGameBox(){
		var game_box = $('#game_box');
		var w = $(window).width();
		console.log(w + 'asdfj as');
		if ( w < 840){
			var h = (w/1.33);
			$(game_box).height(h); 
		}
		
	if ($('#upbtn').length > 0){
		var upbtn = $('#upbtn');
		$(window).scroll(function(){
			upbtn.css('left',$("#content").outerWidth()+$("#content").offset().left-70);
			if($(window).scrollTop()>$("h2").offset().top-50 || $(window).scrollTop()>$(document).height()/3.33){
				upbtn.css('opacity',1);
			} else {
				upbtn.css('opacity',0);
			}
		});
		
		$(window).resize(function(){
			upbtn.css('left',$("#content").outerWidth()+$("#content").offset().left-70);
		});
		
		upbtn.click(function(e){
			$("html, body").animate({ scrollTop: "0px" },300);
		});
	}		

}