$(document).ready(function(){
	$('.comment_writeanswer > a').click(function(){
		if ($('.comment_writeanswer > a').css('display','none'))
		{$('.comment_writeanswer > a').css('display','block');}
		$(this).css('display','none');
		$('.comment_form_close').css('display','block');
		$('#comment_form').detach().prependTo($(this).parents('.comment_content').next());
		$("input[name='parent']").val($(this).attr('data'));
		return false;
	});
	$('.comment_form_close > a').click(function(){
		if ($('.comment_writeanswer > a').css('display','none'))
		{$('.comment_writeanswer > a').css('display','block');}
		$('#comment_form').detach().prependTo('.comment_form_block_main');
		$('.comment_form_close').css('display','none');
		return false;
	});
	if($("input[name='access']").length > 0){
		$("input[name='access']").val('up');
	}
	
});